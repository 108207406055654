import React from 'react';
import type { ButtonVariant } from '#components/Button';
import BaseButton from '#components/Button';
import type { ContentfulComponentDefinition } from '#components/ContentfulComponent';
import { Box, styled } from '@mui/material';
import type { LinkDefinition } from '#contentful/Link';
import getButtonStyles from './_getButtonStyles';

export type ButtonDefinition = ContentfulComponentDefinition & {
  title: string,
  link: LinkDefinition,
  color: {
    value: string
  },
  paddingBottom: string,
  paddingTop: string,
  alignment: string,
  id: string,
  variant?: 'Blue' | 'Transparent' | 'White' | 'Green',
  internal: {
    type: 'ContentfulComponentButton'
  }
};

export type ButtonProps = {
  content: ButtonDefinition,
  className?: string,
};

/**
 * Contentful Content Model Name: "Component: Button"
 *
 * URL: https://app.contentful.com/spaces/0vvalmm98slw/content_types/componentButton/fields
 */
const Button = ({
  content: {
    color,
    link,
    title,
    alignment,
    variant,
  },
  className,
}: ButtonProps) => {
  const { variant: buttonVariant, fontColor } = getButtonStyles(variant, color?.value);

  return (
    <Box
      className={className}
      sx={{
        textAlign: alignment,
        display: 'flex',
        justifyContent: alignment,
      }}
    >
      {link
        ? (
          <BaseButton
            variant={buttonVariant as ButtonVariant}
            href={link.href}
            anchorTag={link?.anchorTag?.customAnchorTag
              || link?.anchorTag?.id
              || link?.manualAnchorTag}
            slug={link?.page?.slug}
            fontColor={fontColor}
          >
            {title}
          </BaseButton>
        )
        : (
          <BaseButton variant={buttonVariant as ButtonVariant} fontColor={fontColor}>
            {title}
          </BaseButton>
        )}
    </Box>
  );
};

export default styled(Button)({});
