import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

//  needs to adjust for bio variant
export const PillButton = styled(Button)({
  borderRadius: 500,
});

export const SquarishButton = styled(Button)({
  width: 150,
  height: 150,
  borderRadius: 0,
});
