import React, { useState } from 'react';
import {
  Box,
  Grid,
  useTheme,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { navigate } from 'gatsby';

import Button from '#components/Button';
import FormField from '#contentful/Form/FormField';
import type { AnyFormFieldDefinition } from '#contentful/Form/FormField';
import { isEmpty } from 'lodash';
import type {
  ContentfulComponentDefinition,
} from '../ContentfulComponentDefinition';
import type { FormFieldTextDefinition } from './FormField/FormFieldText';

export type FormDefinition = ContentfulComponentDefinition & FormProps & {
  submitApiUrl: string,
  successMessage: string,
  formFields: AnyFormFieldDefinition[],
  googleAds: boolean,
  showClearButton: boolean,
  submitButtonText: string,
  buttonAlignment: 'Center' | 'Left' | 'Right',
  internal: {
    type: 'ContentfulComponentForm'
  }
};

type Payload = Record<string, string> | {};

export type FormProps = {
  content: FormDefinition
  pageRedirection?: string | null
};

const formatKey = (key: string) => key.toLowerCase().replaceAll(' ', '');

const translationKey = 'Components.Form';

const Form = ({
  content: {
    submitApiUrl,
    successMessage,
    googleAds,
    showClearButton,
    submitButtonText,
    buttonAlignment,
    formFields,
  },
  pageRedirection = null,
}: FormProps) => {
  const [activeInvalidFields, setActiveInvalidFields] = useState<HTMLFormElement[] | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reset, setReset] = useState<number>(0);
  const { palette } = useTheme();
  const { t } = useTranslation();

  const errorMessage = t(`${translationKey}.ErrorMessage`);

  const resetForm = (form?: HTMLFormElement) => {
    if (form) {
      form.reset();
    }
    setReset(reset + 1);
    setStatusMessage(null);
  };

  const setFormFailure = () => {
    setStatusMessage(errorMessage);
    setIsSuccess(false);
  };

  const setFieldsValidationStyles = (field: Element, hasError: boolean) => {
    const inputField = field;
    const fieldName = field.nodeName;
    const firstParentElement = field.parentElement;
    const borderBottom = hasError ? `2px solid ${palette.color?.danger}` : '';
    const radioLabelColor = hasError ? `${palette.color?.danger}` : '';

    if (fieldName === 'INPUT') {
      (inputField as HTMLInputElement).style.borderBottom = borderBottom;
    }

    if (fieldName === 'TEXTAREA' && firstParentElement) {
      firstParentElement.style.borderBottom = borderBottom;
    }

    if ((field.getAttribute('type') === 'radio')
      && firstParentElement
      && firstParentElement.parentElement
    ) {
      firstParentElement.parentElement.style.color = radioLabelColor;
    }

    if (field.getAttribute('class')?.match('MuiSelect-nativeInput')) {
      firstParentElement!.style.borderBottom = borderBottom;
    }
  };

  const resetInvalidFields = () => {
    if (activeInvalidFields && activeInvalidFields.length) {
      activeInvalidFields.forEach((field) => setFieldsValidationStyles(field, false));
      setActiveInvalidFields(null);
    }
  };
  const handleRedirection = (pageSlug: string) => {
    sessionStorage.setItem('currentSessionPageSlug', pageSlug);
    navigate(`/${pageSlug}`);
  };

  const fetchData = (payload: Payload, form: HTMLFormElement) => {
    fetch(submitApiUrl, {
      method: 'POST',
      body: JSON.stringify(payload) ?? null,
    })
      .then((response) => response.json())
      .then((data) => {
        setIsSuccess(data.isSuccess);
        resetInvalidFields();

        if (data.isSuccess) {
          resetForm(form);
          setStatusMessage(isEmpty(successMessage) ? data.message : successMessage);
        }

        if (!data.isSuccess) {
          setStatusMessage(data.message);
        }

        setIsLoading(false);
      }).then(() => {
        if (pageRedirection) {
          handleRedirection(pageRedirection);
        }
      })
      .catch(() => {
        setIsSuccess(false);
        setStatusMessage(t(`${translationKey}.FailMessage`));
        setIsLoading(false);
      });
  };

  const processInvalidForm = (form: HTMLFormElement) => {
    resetInvalidFields();
    const invalidFields: NodeListOf<HTMLFormElement> = form.querySelectorAll(':invalid');
    if (!invalidFields) {
      setActiveInvalidFields(null);
      return;
    }

    invalidFields.forEach((field) => setFieldsValidationStyles(field, true));
    setActiveInvalidFields(Object.values(invalidFields));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const payload: Record<string, string | boolean> | {} = {};
    const fieldsOverMaxCount = [];
    let allRequiredValuesAreProperlyFilled = true;

    for (const [key, value] of formData) {
      const keyName = formatKey(key);
      const field = formFields.find((v) => v.name === key);
      if (typeof value === 'string') {
        if (
          (field as FormFieldTextDefinition)?.maxCharacterCount
        && value.length > (field as FormFieldTextDefinition)?.maxCharacterCount!
        ) {
          fieldsOverMaxCount.push(field);
        }

        if (field?.required && isEmpty((value as string)?.trim())) {
          allRequiredValuesAreProperlyFilled = false;
        }
      }

      Object.assign(payload, { [keyName]: value });
    }

    const additionalPayload = googleAds != null ? { googleAds } : {};

    if (!form.checkValidity()
        || !isEmpty(fieldsOverMaxCount)
        || !allRequiredValuesAreProperlyFilled) {
      processInvalidForm(form);
      setFormFailure();
      setIsLoading(false);
      return;
    }

    resetInvalidFields();

    fetchData({ ...payload, ...additionalPayload }, form);
  };

  return (
    <Box px={2} minWidth={{ xs: 200, md: 400 }}>
      <form
        onSubmit={onSubmit}
        noValidate
      >
        <Grid
          container
          justifyContent="center"
          rowSpacing={5.25}
        >
          {formFields?.map((field: AnyFormFieldDefinition) => (
            <Grid
              key={field.id}
              item
              xs={12}
              sm={10}
              md={8}
              lg={12}
              pt={0}
              sx={{
                ...(field?.defaultValue && { display: 'none' }),
              }}
            >
              <FormField
                field={field}
                reset={reset}
              />
            </Grid>
          ))}

          {statusMessage && (
            <Alert
              severity={isSuccess ? 'success' : 'error'}
              sx={{ mb: 1, mt: 1 }}
            >
              { statusMessage }
            </Alert>
          )}

          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            lg={12}
            container
            justifyContent={buttonAlignment || 'right'}
            columnSpacing={3}
            sx={{
              paddingTop: statusMessage ? '10px !important' : 4,
              marginLeft: 0,
            }}
          >
            {showClearButton !== false && (
            <Grid item>
              <Button
                onClick={() => resetForm()}
                type="reset"
                variant="secondary"
              >
                {t(`${translationKey}.ResetButton`)}
              </Button>
            </Grid>
            )}
            <Grid item>
              <Button
                isLoading={isLoading}
                type="submit"
                variant="primary"
              >
                {submitButtonText || t(`${translationKey}.SubmitButton`)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
