import React, { useRef } from 'react';
import {
  Box,
  List,
  Paper,
  Popper,
  useTheme,
} from '@mui/material';
import { useToggle } from '@react-shanties/core';

import type { NavMenuLinkDefinition } from '#components/NavMenuLink';
import NavMenuLink from '#components/NavMenuLink';
import type { ExpandedNavigationDefinition } from '#contentful/ExpandedNavigation';
import ExpandedNavigation from '#contentful/ExpandedNavigation';

export type NavMenuCategoryProps = NavMenuLinkDefinition & {
  contentfulId: string,
  subItems: NavMenuLinkDefinition[],
  expandedItem?: ExpandedNavigationDefinition,
};

const NavMenuCategory = ({
  label,
  slug,
  subItems,
  contentfulId,
  expandedItem,
}: NavMenuCategoryProps) => {
  const anchor = useRef(null);

  const [menuOpen,, { setOn, setOff }] = useToggle();
  const { palette } = useTheme();

  const isContactCategory = contentfulId === '1cRvq5jA5pCenL84ClJpsh';

  return (
    <Box
      ref={anchor}
      sx={{
        height: isContactCategory ? '70%' : '100%',
        margin: 0,
        marginTop: isContactCategory ? '10px' : 0,
        typography: 'body2',
      }}
      onMouseEnter={setOn}
      onMouseLeave={setOff}
    >
      <NavMenuLink
        label={label}
        color={isContactCategory ? palette?.color?.impblue500 : ''}
        listStyles={isContactCategory
          ? {
            border: `solid 1px ${palette?.color?.impblue300}`,
            borderRadius: '32px',
            backgroundImage: palette?.gradient?.lightBlueLightGreenSoft,
          } : {}}
        slug={slug}
      />

      {expandedItem && (
        <Popper
          anchorEl={anchor.current}
          open={menuOpen}
          placement="bottom-start"
          keepMounted
          onResize={undefined}
          onResizeCapture={undefined}
          sx={{
            zIndex: 1000000,
            width: {
              xs: '100%',
            },
            boxSizing: 'border-box',
            background: 'transparent',
          }}
        >
          <ExpandedNavigation
            content={expandedItem}
          />
        </Popper>
      )}

      {!expandedItem && subItems && (
        <Popper
          anchorEl={anchor.current}
          open={menuOpen}
          placement="bottom-start"
          keepMounted
          onResize={undefined}
          onResizeCapture={undefined}
          sx={{ zIndex: 1000000 }}
        >
          <Paper>
            <List disablePadding>
              {
              subItems.map(
                (subItem) => (
                  <NavMenuLink
                    key={subItem.id}
                    listStyles={{ width: 150 }}
                    label={subItem.label}
                    href={subItem.href}
                    slug={subItem.slug}
                  />
                ),
              )
            }
            </List>
          </Paper>
        </Popper>
      )}
    </Box>
  );
};

export default NavMenuCategory;
