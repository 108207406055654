import React from 'react';
import { Grid } from '@mui/material';

import formatDateTime from '#utils/formatDateTime';
import useViewport, { viewport } from '#hooks/useViewport';

export type DateRangeProps = {
  start: Date,
  end: Date,
};

const DateRange = ({
  start,
  end,
}: DateRangeProps) => {
  const activeViewport = useViewport();
  const largeScreen = [
    viewport.desktop,
  ].includes(activeViewport);

  const sameDay = start.getDate() === end.getDate();
  const sameMonth = start.getMonth() === end.getMonth();

  const includeDash = !sameDay && (sameMonth || largeScreen);
  const spaceDates = !sameMonth;

  const startDate = formatDateTime(start);
  const endDate = (): string => {
    if (sameDay) {
      return '';
    }

    return `${formatDateTime(
      end,
      {
        day: 'numeric',
        month: sameMonth
          ? undefined
          : 'short',
      },
    )}`;
  };

  return (
    <Grid
      container
      direction={includeDash ? 'row' : 'column'}
      columnGap={spaceDates ? 0.5 : 0.2}
      data-testid="date-range"
    >
      <Grid item>
        {startDate}
      </Grid>
      {includeDash ? '-' : ''}
      <Grid item>
        {endDate()}
      </Grid>
    </Grid>
  );
};

export default DateRange;
