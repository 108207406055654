import React from 'react';
import { Grid } from '@mui/material';

import type { CarouselComponentDefinition } from '#components/Carousel/CarouselItem/CarouselComponent';
import type { ContentfulColumnSettingsDefinition } from '#hooks/useResponsiveColumnSettings';
import useResponsiveColumnSettings from '#hooks/useResponsiveColumnSettings';
import CarouselComponent from '#components/Carousel/CarouselItem/CarouselComponent';
import useColumnSpan from '#hooks/useColumnSpan';
import type { ContentfulColumnSpan } from '#hooks/useColumnSpan/types';
import type { ContentfulBorderDefinition } from '#hooks/useBorder';
import useBorder from '#hooks/useBorder';
import type { ContentfulComponentDefinition } from '#contentful/ContentfulComponentDefinition';
import type { ImageCarouselComponentDefinition } from '#components/ImageCarousel/ImageCarouselItem/ImageCarouselComponent';
import ImageCarouselComponent from '#components/ImageCarousel/ImageCarouselItem/ImageCarouselComponent';
import type { BackgroundDefinition } from '#components/Section';

export type ColumnDefinition = ColumnProps
& ContentfulComponentDefinition
& {
  internal: {
    type: 'ContentfulSubComponentColumn',
  }
};

export type ColumnProps = {
  components: CarouselComponentDefinition[] | ImageCarouselComponentDefinition[],
  span?: ContentfulColumnSpan,
  spacingSettings?: ContentfulColumnSettingsDefinition,
  border?: ContentfulBorderDefinition,
  background?: BackgroundDefinition,
  carouselType?: 'carousel' | 'imageCarousel',
};

const Column = ({
  components = [],
  span,
  spacingSettings,
  border,
  carouselType = 'carousel',
  background,
}: ColumnProps) => {
  const {
    pt,
    pb,
    pr,
    pl,
    justifyContent,
    alignItems,
  } = useResponsiveColumnSettings({
    itemsHorizontalAlignment: carouselType === 'carousel' ? 'left' : 'center',
    ...spacingSettings,
  });
  const columnSpan = useColumnSpan(span);
  const borderStyling = useBorder(border);

  console.log('Color');
  console.log(background);

  return (
    <Grid
      item
      pt={pt}
      pb={pb}
      pr={pr}
      pl={pl}
      xs={12}
      lg={columnSpan}
      {...borderStyling}
      container
      direction="column"
      wrap="nowrap"
      justifyContent={justifyContent}
      alignItems={alignItems}
      rowSpacing={1}
      sx={{ bgcolor: background ? background?.color?.value : 'white' }}
    >
      {
        components.map((content, index) => (
          <Grid key={content.id} item>
            {carouselType === 'carousel' ? (
              <CarouselComponent content={content as CarouselComponentDefinition} />
            ) : (
              index === 0
              && <ImageCarouselComponent content={content as ImageCarouselComponentDefinition} />
            )}
          </Grid>
        ))
      }
    </Grid>
  );
};

export default Column;
