import React from 'react';
import type { PropsWithChildren } from 'react';
import type { BoxProps } from '@mui/material';
import { styled, Typography } from '@mui/material';

import SmartLink from '#components/SmartLink';

import StyledBox from './styles';

export type LinkCellProps = PropsWithChildren<{
  slug?: string,
  href?: string,
  anchorTag?: string,
  className?: string,
  minWidth?: BoxProps['minWidth'],
}>;

const LinkCell = ({
  slug,
  href,
  anchorTag,
  className,
  minWidth,
  children,
}: LinkCellProps) => (
  <StyledBox minWidth={minWidth} className={className}>
    <SmartLink
      slug={slug}
      href={href}
      anchorTag={anchorTag}
    >
      <Typography variant="subtitle1">
        {children}
      </Typography>
    </SmartLink>
  </StyledBox>
);

export default styled(LinkCell)({});
