import { find } from 'lodash/fp';
import type { JobTableDefinition } from '../models/JobTableDefinition';
import getOpenPositions from '../getOpenPositions';

export default (guid: string): Promise<JobTableDefinition> => (
  getOpenPositions()
    .then((positions) => {
      const listing = find({ guid }, positions);

      if (!listing) {
        throw new Error('Listing not found');
      }

      return listing;
    }));
