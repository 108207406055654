import React from 'react';
import type { TableContentDefinition } from '#contentful/TableContent';
import TableContent from '#contentful/TableContent';
import FullWidth from './Sections/FullWidth';
import Sec1212 from './Sections/Sec1212';
import Sec131313 from './Sections/Sec131313';
import Sec1323 from './Sections/Sec1323';
import Sec14141414 from './Sections/Sec14141414';
import Sec2313 from './Sections/Sec2313';
import type { FullWidthSection } from './Sections/FullWidth';
import type { TwoColumnSectionDefinition } from './Sections/TwoColumnSectionDefinition';
import type { ThreeColumnSectionDefinition } from './Sections/ThreeColumnSectionDefinition';
import type { FourColumnSectionDefinition } from './Sections/FourColumnSectionDefinition';

export type AnySectionDefinition = (
  FullWidthSection
  | TwoColumnSectionDefinition<string>
  | ThreeColumnSectionDefinition<string>
  | FourColumnSectionDefinition<string>
  | TableContentDefinition
);

export type SectionProps = {
  section: AnySectionDefinition,
};

const Section = ({
  section,
}: SectionProps) => {
  switch (section.internal?.type) {
    case 'ContentfulSectionFullWidth':
      return <FullWidth key={section.id} content={section as FullWidthSection} />;
    case 'ContentfulSection1212':
      return <Sec1212 key={section.id} content={section as TwoColumnSectionDefinition<'1212'>} />;
    case 'ContentfulSection1323':
      return <Sec1323 key={section.id} content={section as TwoColumnSectionDefinition<'1323'>} />;
    case 'ContentfulSection2313':
      return <Sec2313 key={section.id} content={section as TwoColumnSectionDefinition<'2313'>} />;
    case 'ContentfulSection131313':
      return <Sec131313 key={section.id} content={section as ThreeColumnSectionDefinition<'131313'>} />;
    case 'ContentfulSection14141414':
      return <Sec14141414 key={section.id} content={section as FourColumnSectionDefinition<'14141414'>} />;
    case 'ContentfulComponentTableContent':
      return <TableContent key={section.id} content={section as TableContentDefinition} />;
    default:
      throw new Error(`Unexpected section type: ${section.internal?.type}`);
  }
};

export default Section;
