import GeolocationProvider from '#providers/GeolocationProvider';
import { ThemeProvider } from '@mui/material';
import { withProviders } from '@react-shanties/core';
import Layout from './Layout';
import baseTheme from './theme';

export default withProviders([
  [ThemeProvider, { theme: baseTheme }],
  GeolocationProvider,
], Layout);

export { baseTheme as theme };

export type { ColorIndex } from './theme/palette.theme';
