import React, { useRef } from 'react';
import {
  Box,
  Grid,
  Popper,
  Paper,
  Typography,
  useTheme,
  List,
  ListItemText,
  ListItem,
} from '@mui/material';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import { useToggle } from '@react-shanties/core';
import { first, map, split } from 'lodash/fp';

import { langs } from './languages';

const LanguageSelector = () => {
  const { palette } = useTheme();

  const anchor = useRef(null);
  const [menuOpen,, { setOn, setOff }] = useToggle();

  const { language, languages, originalPath } = useI18next();

  const getPath = () => {
    if (originalPath === '/404/') {
      return '/404.html';
    }
    return originalPath;
  };

  return (
    <Box
      ref={anchor}
      onMouseEnter={setOn}
      onMouseLeave={setOff}
      border={1}
      borderRadius={20}
      borderColor={palette.color?.impblue500}
      px={1}
      sx={{
        margin: 0,
      }}
      data-testid="language-selector-box"
    >
      <Grid
        container
        alignItems="center"
        columnSpacing={1}
      >
        <Grid item xs={6} mt={0.5} data-testid="language-icon">
          <LanguageIcon color="primary" />
        </Grid>
        <Grid item xs={6} mb={0.25}>
          <Typography
            variant="subtitle1"
            color={palette.color?.impblue500}
          >
            {first(split('-', language))}
          </Typography>
        </Grid>
      </Grid>
      {languages && anchor.current && (
        <Popper
          anchorEl={anchor.current}
          open={menuOpen}
          placement="bottom-start"
          keepMounted
          onResize={undefined}
          onResetCapture={undefined}
          sx={{ zIndex: 1000000 }}
        >
          <Paper>
            <List disablePadding>
              {map((lang) => (
                <ListItem
                  sx={{ height: '100%', width: 110 }}
                  key={lang}
                >
                  <Link
                    to={getPath()}
                    language={lang}
                    style={{ textDecoration: 'none' }}
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    <ListItemText style={{ margin: 0 }}>
                      <Typography
                        variant="subtitle1"
                        color={palette.color?.impblue500}
                      >
                        {langs[lang]}
                      </Typography>
                    </ListItemText>
                  </Link>
                </ListItem>
              ), languages)}
            </List>
          </Paper>
        </Popper>
      )}
    </Box>
  );
};

export default LanguageSelector;
