import React, { useCallback, useState } from 'react';
import {
  IconButton,
  SwipeableDrawer,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Box,
  Typography,
  ListItemText,
  ListItem,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  ExpandMore,
} from '@mui/icons-material';
import { map } from 'lodash/fp';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import loadable from '@loadable/component';

import type { NavMenuCategoryProps } from '#components/NavMenuCategory';
import NavMenuLink from '#components/NavMenuLink';
import SmartLink from '#components/SmartLink';

import { langs } from '#components/LanguageSelector';

const PrimaryLogo = loadable(() => import('#components/PrimaryLogo'));

export type NavBarMobileProps = {
  menu: NavMenuCategoryProps[],
};

const NavBarMobile = ({
  menu,
}: NavBarMobileProps) => {
  const [open, setOpen] = useState(false);
  const { palette } = useTheme();

  const toggleDrawer = (openDrawer: boolean) => (event: any) => {
    if (event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(openDrawer);
  };

  const [expanded, setExpanded] = useState<string | false>(false);
  const langId = 'mobile-language-selector';

  const { language, languages, originalPath } = useI18next();

  const handleChange = useCallback(
    (panel: string) => (
      _event: React.SyntheticEvent,
      newExpanded: boolean,
    ) => {
      setExpanded(newExpanded ? panel : false);
    },
    [],
  );

  const isLanguageEnabled = process.env.GATSBY_ENABLE_LANG === 'true';

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        marginBottom={1}
      >
        <Grid item>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            sx={{ mx: 0 }}
          >
            <MenuIcon htmlColor={palette.color?.impgreen500} />
          </IconButton>
        </Grid>

        <Grid item>
          <SmartLink slug="/" ariaLabel="Improving Home Page">
            <PrimaryLogo />
          </SmartLink>
        </Grid>
        <Grid item />
      </Grid>

      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{
          sx: {
            width: '80%',
            background: palette.color?.impblue400,
          },
        }}
      >
        <Grid
          container
          direction="column"
          alignItems="stretch"
          gap={4}
        >
          <Grid p={2} item>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon
                htmlColor={palette.color?.impgreen500}
              />
            </IconButton>
          </Grid>

          {isLanguageEnabled && (
          <Grid item>
            <Accordion
              id={langId}
              elevation={0}
              expanded={expanded === langId}
              onChange={handleChange(langId)}
              sx={{
                backgroundColor: 'inherit',
                '&:before': {
                  display: 'none',
                },
              }}
            >
              <AccordionSummary
                sx={{ px: 2 }}
                expandIcon={<ExpandMore htmlColor={palette.color?.impgreen500} />}
                aria-controls="language-selector"
              >
                <LanguageIcon color="secondary" sx={{ px: 1 }} />
                <Typography
                  variant="subtitle1"
                  color="white"
                >
                  {langs[language]}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ background: palette.color?.impblue300 }}>
                {map(
                  (lang) => (
                    <ListItem
                      sx={{ height: '100%' }}
                      key={lang}
                    >
                      <Link
                        to={originalPath}
                        language={lang}
                        onClick={toggleDrawer(false)}
                        style={{ textDecoration: 'none' }}
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      >
                        <ListItemText>
                          <Typography
                            variant="subtitle1"
                            color="white"
                          >
                            {langs[lang]}
                          </Typography>
                        </ListItemText>
                      </Link>
                    </ListItem>
                  ),
                  languages,
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
          )}

          <Grid item>
            {map(
              ({
                id,
                label,
                slug,
                subItems,
              }: NavMenuCategoryProps) => (!subItems.length
                ? (
                  <Box
                    key={id}
                    alignItems="center"
                    display="flex"
                    height="71px"
                  >
                    <NavMenuLink
                      label={label}
                      slug={slug}
                      onClick={toggleDrawer(false)}
                      color="white"
                      listStyles={{
                        boxSizing: 'border-box',
                        height: 'auto',
                        marginLeft: '16px',
                        width: 'max-content',
                      }}
                    />
                  </Box>
                ) : (
                  <Accordion
                    key={id}
                    elevation={0}
                    expanded={expanded === id}
                    onChange={handleChange(id)}
                    sx={{
                      backgroundColor: 'inherit',
                      '&:before': {
                        display: 'none',
                      },
                    }}
                  >
                    <AccordionSummary
                      sx={{ px: 2 }}
                      expandIcon={<ExpandMore htmlColor={palette.color?.impgreen500} />}
                      aria-controls={`${label}-nav-link`}
                    >
                      <NavMenuLink
                        key={id}
                        label={label}
                        slug={slug}
                        onClick={toggleDrawer(false)}
                        color="white"
                      />
                    </AccordionSummary>
                    <AccordionDetails sx={{ background: palette.color?.impblue300 }}>
                      {subItems.map(
                        (subItem) => (
                          <NavMenuLink
                            key={subItem.id}
                            label={subItem.label}
                            slug={subItem.slug}
                            onClick={toggleDrawer(false)}
                            color="white"
                          />
                        ),
                      )}
                    </AccordionDetails>
                  </Accordion>
                )),
              menu,
            )}
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </>
  );
};

export default NavBarMobile;
