import React from 'react';
import { Grid, Typography } from '@mui/material';

import paletteTheme from '#components/Layout/theme/palette.theme';
import DateRange from '#components/DataTable/ScheduleCell/DateRange';
import TimeRange from '#components/DataTable/ScheduleCell/TimeRange';
import useDate from './_useDate';

export type ScheduleCellProps = {
  startDateTime?: string | Date,
  endDateTime?: string | Date,
  duration?: string,
};

const ScheduleCell = ({
  startDateTime,
  endDateTime,
  duration,
}: ScheduleCellProps) => {
  const isDateRange = startDateTime && endDateTime;
  const durationText = duration ? `Duration: ${duration}` : '';

  const start = useDate(startDateTime ?? '');
  const end = useDate(endDateTime ?? '');

  return (
    <Typography
      variant="subtitle1"
      textAlign="left"
      sx={{
        width: '100%',
        color: paletteTheme().color.impgray900,
      }}
    >
      <Grid
        textAlign="left"
        py={{ xs: 1.5, md: 3, xl: 3 }}
      >
        <Grid item xs={12}>
          <b>
            {isDateRange ? (
              <DateRange
                start={start}
                end={end}
              />
            ) : (
              <>
                Privately Offered
              </>
            )}
          </b>
        </Grid>
        <Grid item xs={12}>
          {isDateRange ? (
            <TimeRange
              start={start}
              end={end}
            />
          ) : durationText}
        </Grid>
      </Grid>
    </Typography>
  );
};

export default ScheduleCell;
