import React from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  Typography,
  useTheme,
} from '@mui/material';

import type { RichTextContent } from '#components/RichText';
import type {
  ContentfulComponentDefinition,
} from '#contentful/ContentfulComponentDefinition';
import RichText from '#components/RichText';
import columnCountOptions from '#utils/columnCountOptions';
import getMinHeight from '#utils/getMinHeight';
import AccordionSummary from './AccordionSummary';

export type AccordionItemDefinition = ContentfulComponentDefinition & {
  accordionTitle: RichTextContent,
  titleHeight: 'Small' | 'Medium' | 'Large',
  titleBackgroundColor: {
    value: string
  },
  content: RichTextContent,
  contentBackgroundColor: {
    value: string
  },
  listColumnCount: 1 | 2,
  id: string,
  internal: {
    type: 'ContentfulSubComponentAccordionItem'
  },
};

export type AccordionItemProps = Omit<AccordionItemDefinition, 'internal'> & {
  expanded: string | false,
  handleChange: (panel: string) => (
    event: React.SyntheticEvent,
    newExpanded: boolean
  ) => void,
};

const AccordionItem = ({
  id,
  accordionTitle,
  titleHeight,
  titleBackgroundColor,
  content,
  contentBackgroundColor,
  listColumnCount,
  expanded,
  handleChange,
}: AccordionItemProps) => {
  const { color } = useTheme().palette;
  const customOptions = columnCountOptions(listColumnCount);

  const minHeight = getMinHeight(titleHeight);

  return (
    <MuiAccordion
      expanded={expanded === id}
      onChange={handleChange(id)}
    >
      <AccordionSummary
        sx={{
          px: 2.7,
          py: 1,
          background: titleBackgroundColor?.value,
          minHeight,
          '&.Mui-expanded': {
            minHeight,
          },
        }}
        aria-controls={`${accordionTitle} - content`}
      >
        <RichText
          content={accordionTitle}
          options={customOptions}
        />
      </AccordionSummary>
      <MuiAccordionDetails sx={{ p: 2.7, background: contentBackgroundColor?.value }}>
        <Typography color={color?.impgray900} component="div">
          <RichText
            content={content}
            options={customOptions}
          />
        </Typography>
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default AccordionItem;
