import type { ColumnSettingOptions } from './types';

const defaults: ColumnSettingOptions = {
  ptxs: 2,
  pbxs: 2,
  prxs: 0,
  plxs: 0,
  ptmd: 2,
  pbmd: 2,
  prmd: 2,
  plmd: 2,
  verticalAlignment: 'top',
  horizontalAlignment: 'full width',
  justifyContentxs: 'flex-start',
  alignItemsxs: 'stretch',
};

export default defaults;
