import React, { useState } from 'react';
import { Box, Tab } from '@mui/material';
import Image from '#contentful/Image';
import getAlignment from '#utils/getAlignment';
import type { TabItem } from '../TabPanel';

type TabLabelProps = TabItem & {
  onChange: (event: React.SyntheticEvent, newSelectedTab: number) => void;
  index: number;
  active: boolean;
};

const TabLabel: React.FC<TabLabelProps> = ({
  title,
  options,
  onChange,
  index,
  active,
}) => {
  const {
    textTransform,
    textAlign,
    tabItemDefaultIcon,
    tabItemActiveIcon,
    tabItemHoverIcon,
    iconPosition,
    tabItemDefaultBackground,
    tabItemDefaultFontColor,
    tabItemHoverBackground,
    tabItemHoverFontColor,
    tabItemActiveBackground,
    tabItemActiveFontColor,
  } = options ?? {};

  const [isHovered, setIsHovered] = useState(false);

  const baseStyles = {
    background: tabItemDefaultBackground?.value || 'transparent',
    color: tabItemDefaultFontColor?.value || '#000',
    opacity: 1,
    alignItems: getAlignment(textAlign),
    fontWeight: 700,
    fontSize: {
      xs: '0.75rem',
      md: '0.85rem',
    },
    minHeight: '60px',
    maxWidth: {
      xs: '100px',
      md: '270px',
    },
    flexGrow: 1,
    wordWrap: 'break-word',
    wordBreak: {
      xs: 'break-word',
      md: 'normal',
    },
    textWrap: {
      xs: 'wrap',
      md: 'none',
    },
    textAlign: textAlign ?? 'left',
    textTransform: textTransform?.toLowerCase() ?? 'capitalize',
    '&:hover': {
      background: tabItemHoverBackground?.value || tabItemDefaultBackground?.value,
      color: tabItemHoverFontColor?.value || tabItemDefaultFontColor?.value,
    },
    ...(active && {
      background: tabItemActiveBackground?.value || tabItemDefaultBackground?.value,
      color: tabItemActiveFontColor?.value || tabItemDefaultFontColor?.value,
    }),
  };

  let iconElement;

  if (active && tabItemActiveIcon) {
    iconElement = <Box width="35px"><Image content={tabItemActiveIcon} /></Box>;
  } else if (isHovered && tabItemHoverIcon) {
    iconElement = <Box width="35px"><Image content={tabItemHoverIcon} /></Box>;
  } else if (tabItemDefaultIcon) {
    iconElement = <Box width="35px"><Image content={tabItemDefaultIcon} /></Box>;
  }

  return (
    <Tab
      wrapped
      label={title}
      icon={iconElement}
      iconPosition={iconPosition || 'top'}
      sx={baseStyles}
      onClick={(event) => {
        onChange(event, index);
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    />
  );
};

export default TabLabel;
