import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import type { CurrencyCode } from '#services/arlo';

export type PriceCellProps = {
  basePrice: string,
  discountPrice?: string,
  currencyCode?: CurrencyCode,
};

const translationKey = 'Components.PriceCell';

const PriceCell = ({
  basePrice,
  discountPrice,
  currencyCode = 'USD',
}: PriceCellProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  return (
    <Grid container sx={{ color: palette.color?.impgray900 }}>
      {discountPrice && (
        <Grid item xs={12}>
          <Typography
            display="block"
            variant="body3"
            textAlign="left"
            sx={{ textDecoration: 'line-through', mb: 0 }}
          >
            {`${basePrice} ${currencyCode}`}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography
          display="inline-block"
          variant="subtitle1"
          textAlign="left"
        >
          <b>
            {`${discountPrice ?? basePrice} ${currencyCode}`}
          </b>
        </Typography>
      </Grid>
      {discountPrice && (
      <Grid item xs={12}>
        <Typography
          variant="body3"
          textAlign="left"
        >
          <i>
            {t(`${translationKey}.EarlyBird`)}
          </i>
        </Typography>
      </Grid>
      )}
    </Grid>
  );
};

export default PriceCell;
