import { Box, styled } from '@mui/material';

export default styled(Box)(({ theme }) => theme.unstable_sx({
  textAlign: 'left',
  overflowWrap: 'break-word',
  '& a': {
    color: 'color.impgray900',
    '&:hover': {
      color: 'color.impgreen500',
    },
    '& h6': {
      color: 'color.impgray900',
      '&:hover': {
        color: 'color.impgreen500',
      },
    },
  },
  '& h6': {
    color: 'color.impgray900',
  },
}));
