import React, { useState } from 'react';
import {
  Box,
  Collapse,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useContentfulImage } from 'gatsby-source-contentful/hooks';
import { GatsbyImage } from 'gatsby-plugin-image';
import AddIcon from '@mui/icons-material/Add';
import ForwardIcon from '@mui/icons-material/Forward';

import type { GatsbyImageProps } from '#contentful/Image';
import type { TextDefinition } from '#contentful/Text';
import Text from '#contentful/Text';
import type { ContentfulBorderDefinition } from '#hooks/useBorder';
import useBorder from '#hooks/useBorder';
import type { TextVariantDefinition } from '#contentful/TextVariant';
import TextVariant from '#contentful/TextVariant';
import type { LinkDefinition } from '#contentful/Link';
import SmartLink from '#components/SmartLink';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export type ContentCardDefinition = ContentCardProps & {
  internal: {
    type: 'ContentfulComponentContentCard',
  },
  id: string,
};

type ContentCardProps = {
  titleIcon?: GatsbyImageProps,
  iconPosition: string,
  titleVariant?: TextVariantDefinition,
  titleAlignment: 'Center' | 'Left' | 'Right',
  text?: TextDefinition,
  border?: ContentfulBorderDefinition,
  backgroundColor?: {
    value: string,
  },
  link?: LinkDefinition,
  linkFontColor?: {
    value: string,
  },
  expandableText?: TextDefinition,
  expandableLabel?: string,
};

const translationKey = 'Components.ContentCard';

const ContentCard = ({
  titleIcon,
  iconPosition,
  titleVariant,
  titleAlignment,
  text,
  border,
  backgroundColor,
  link,
  linkFontColor,
  expandableText,
  expandableLabel,
}: ContentCardProps) => {
  const borderStyles = useBorder(border);
  const { palette } = useTheme();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const iconDimentions = 80;

  const dynamicImage = useContentfulImage({
    image: {
      url: titleIcon?.url.replace('https:', ''),
      width: 600,
      height: 375,
      resizingBehavior: 'crop',
      quality: 100,
    },
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const isIconLeft = iconPosition === 'Left' || iconPosition === null;

  return (
    <Grid
      data-testid="ContentCard"
      className="hover-scale transition-ease-1"
      container
      direction="column"
      justifyContent="space-between"
      minHeight={{ xs: 282, md: 340, lg: expanded ? 'auto' : 282 }}
      maxWidth={{ xs: 600, md: 'none' }}
      sx={{
        position: 'relative',
        p: 2.5,
        backgroundColor: backgroundColor?.value,
        boxShadow: palette.shadow?.darker,
        borderRadius: '12px',
        ...borderStyles,
        overflow: 'hidden',
        '&:hover': {
          '.link-hover-effect': {
            right: '30px',
            transform: 'translateX(0)',
          },
        },
      }}
    >
      {iconPosition === 'Top' && titleIcon && (
        <Grid
          item
          xs={3}
          md={4}
          xl={3}
          display="flex"
          justifyContent="center"
          alignItems={{
            xs: 'center',
            xl: 'flex-start',
          }}
        >
          <GatsbyImage
            loading="lazy"
            image={dynamicImage}
            alt={titleIcon?.alt}
            style={{
              width: iconDimentions,
              height: iconDimentions,
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} pt="12px" container>
        {isIconLeft && titleIcon && (
        <Grid
          item
          xs={3}
          md={4}
          xl={3}
          display="flex"
          justifyContent="center"
          alignItems={{
            xs: 'center',
            xl: 'flex-start',
          }}
        >
          <GatsbyImage
            loading="lazy"
            image={dynamicImage}
            alt={titleIcon?.alt}
            style={{
              width: iconDimentions,
              height: iconDimentions,
            }}
          />
        </Grid>
        )}
        {titleVariant && (
        <Grid
          item
          xs={9}
          md={titleIcon && isIconLeft ? 8 : 12}
          xl={titleIcon && isIconLeft ? 9 : 12}
          textAlign={!titleAlignment ? 'left' : titleAlignment.toLowerCase() as 'left' | 'center' | 'right'}
        >
          <TextVariant {...titleVariant} />
        </Grid>
        )}
      </Grid>
      <Grid item xs={12} p={2}>
        {text && (
        <Text content={text} minWidth="auto" />
        )}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {expandableText && (
            <Text content={expandableText} />
          )}
        </Collapse>
      </Grid>
      {expandableText && !link && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            paddingTop: '8px',
            alignSelf: 'flex-end',
            marginTop: '-30px',
          }}
          onClick={handleExpandClick}
        >
          <Typography
            variant="iconLabel"
            sx={{
              fontWeight: 'bold',
              mr: 0,
              justifyContent: 'center',
              alignItems: 'center',
              marginBlockEnd: '0px',
              color: linkFontColor?.value,
              fontSize: '14px',
            }}
          >
            {expanded ? t(`${translationKey}.Close`) : (expandableLabel || t(`${translationKey}.SeeMore`))}
          </Typography>
          <AddIcon
            sx={{
              color: linkFontColor?.value,
              transform: expanded ? 'rotate(45deg)' : 'rotate(0deg)',
              transition: 'all 0.5s ease-in-out',
              marginTop: expanded ? '2px' : '0',
              fontSize: '16px',
            }}
          />
        </Box>
      )}
      {link && (
        <Box
          className="link-hover-effect"
          sx={{
            position: 'absolute',
            right: '0',
            bottom: '20px',
            transform: 'translateX(100%)',
            transition: 'transform 0.5s ease-in-out',
          }}
        >
          <SmartLink
            href={link?.href}
            slug={link?.page?.slug}
            openInNewWindow={link?.openInNewWindow}
            sx={{
              textDecoration: 'none',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="iconLabel"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  mr: 1,
                  color: linkFontColor?.value,
                }}
              >
                {link?.label}
              </Typography>
              <ForwardIcon
                sx={{
                  color: linkFontColor?.value,
                  fontSize: '20px',
                }}
              />
            </Box>
          </SmartLink>
        </Box>
      )}
    </Grid>
  );
};

export default ContentCard;
