import React, { useState, useMemo } from 'react';
import type { Theme } from '@mui/material';
import { Box, useMediaQuery } from '@mui/material';
import debounce from 'lodash/fp/debounce';
import type { BackgroundDefinition } from '#components/Section';
import type { ContentfulComponentDefinition } from '#contentful/ContentfulComponentDefinition';
import { display } from '@mui/system';
import type { ExpandableColumnComponentDefinition } from './ExpandableColumnComponent';
import ExpandableColumnComponent from './ExpandableColumnComponent';

export type ExpandableColumnProps = {
  content: ExpandableColumnComponentDefinition[],
  background: BackgroundDefinition
};

export type ExpandableColumnsDefinition = ContentfulComponentDefinition & {
  leftColumn: ExpandableColumnProps,
  leftExpandedColumn: ExpandableColumnProps,
  rightColumn: ExpandableColumnProps,
  rightExpandedColumn: ExpandableColumnProps,
  minHeight: number,
  internal: {
    type: 'ContentfulComponentForm'
  }
};

export type ExpandableColumnsProps = {
  content: ExpandableColumnsDefinition,
};

const ExpandableColumns = ({
  content,
}: ExpandableColumnsProps) => {
  const {
    leftColumn,
    leftExpandedColumn,
    rightColumn,
    rightExpandedColumn,
    minHeight,
  } = content || {};

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [activeColumn, setActiveColumn] = useState<'left' | 'right' | null>(null);

  const debouncedSetActiveColumn = useMemo(
    () => debounce(150, (side: 'left' | 'right' | null) => {
      setActiveColumn(side);
    }),
    [],
  );

  const getFlex = (side: 'left' | 'right') => {
    if (activeColumn === side) {
      return 2;
    }
    if (activeColumn) {
      return 0;
    }
    return 1;
  };

  const getContent = (side: 'left' | 'right') => {
    if (side === 'left') {
      return activeColumn === 'left' ? leftExpandedColumn.content : leftColumn.content;
    }
    return activeColumn === 'right' ? rightExpandedColumn.content : rightColumn.content;
  };

  const getBackground = (side: 'left' | 'right') => {
    if (side === 'left') {
      return activeColumn === 'left' ? leftExpandedColumn?.background?.color : leftColumn?.background?.color;
    }
    return activeColumn === 'right' ? rightExpandedColumn?.background?.color : rightColumn?.background?.color;
  };

  const getBackgroundImage = (side: 'left' | 'right') => {
    if (side === 'left') {
      return activeColumn === 'left' ? leftExpandedColumn?.background?.image?.url : leftColumn?.background?.image?.url;
    }
    return activeColumn === 'right' ? rightExpandedColumn?.background?.image?.url : rightColumn?.background?.image?.url;
  };

  const handleColumnToggle = (side: 'left' | 'right') => {
    setActiveColumn((prev) => (prev === side ? null : side));
  };

  const commonBoxProps = {
    transition: 'flex 0.3s ease',
    backgroundSize: 'cover',
    overflow: 'hidden',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Box sx={{
      display: 'flex',
      width: '100%',
      minHeight,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'stretch',
    }}
    >
      <Box
        sx={{
          ...commonBoxProps,
          background: getBackground('left'),
          backgroundImage: `url(${getBackgroundImage('left')})`,
          flex: getFlex('left'),
          display: 'flex',
          flexDirection: 'column',
        }}
        padding={{
          xs: 2,
          md: activeColumn === 'right' ? 2 : 4,
        }}
        onMouseEnter={!isMobile ? () => debouncedSetActiveColumn('left') : undefined}
        onMouseLeave={!isMobile ? () => debouncedSetActiveColumn(null) : undefined}
        onClick={isMobile ? () => handleColumnToggle('left') : undefined}
      >
        {activeColumn !== 'right' && getContent('left').map((componentContent) => (
          <ExpandableColumnComponent key={componentContent.id} content={componentContent} />
        ))}
      </Box>
      <Box
        sx={{
          ...commonBoxProps,
          background: getBackground('right'),
          backgroundImage: `url(${getBackgroundImage('right')})`,
          flex: getFlex('right'),
          flexDirection: 'column',
        }}
        padding={{
          xs: 2,
          md: activeColumn === 'left' ? 2 : 4,
        }}
        onMouseEnter={!isMobile ? () => debouncedSetActiveColumn('right') : undefined}
        onMouseLeave={!isMobile ? () => debouncedSetActiveColumn(null) : undefined}
        onClick={isMobile ? () => handleColumnToggle('right') : undefined}
      >
        {activeColumn !== 'left' && getContent('right').map((componentContent) => (
          <ExpandableColumnComponent key={componentContent.id} content={componentContent} />
        ))}
      </Box>
    </Box>
  );
};

export default ExpandableColumns;
