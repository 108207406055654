import React from 'react';
import { Grid } from '@mui/material';
import { map } from 'lodash/fp';
import loadable from '@loadable/component';

import type { NavMenuCategoryProps } from '#components/NavMenuCategory';
import NavMenuCategory from '#components/NavMenuCategory';
import LanguageSelector from '#components/LanguageSelector';
import SmartLink from '#components/SmartLink';

const PrimaryLogo = loadable(() => import('#components/PrimaryLogo'));

export type NavBarDesktopProps = {
  menu: NavMenuCategoryProps[],
};

const NavBarDesktop = ({
  menu,
}: NavBarDesktopProps) => {
  const isLanguageEnabled = process.env.GATSBY_ENABLE_LANG === 'true';

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="stretch"
      minHeight={64}
    >
      <Grid
        item
        xs={2}
        container
        alignItems="center"
      >
        <SmartLink slug="/" ariaLabel="Improving Home Page">
          <PrimaryLogo />
        </SmartLink>
      </Grid>
      <Grid
        item
        xs={9}
        container
        columnSpacing={2}
        justifyContent="flex-end"
        alignItems="stretch"
      >
        {map(
          (props: NavMenuCategoryProps) => (
            <NavMenuCategory
              key={props.id}
              {...props}
            />
          ),
          menu,
        )}
      </Grid>
      {isLanguageEnabled && (
      <Grid
        item
        xs={1}
        container
        alignItems="center"
        justifyContent="flex-end"
      >
        <LanguageSelector />
      </Grid>
      )}
    </Grid>
  );
};

export default NavBarDesktop;
