export default (buttonVariant?: string, colorValue?: string) => {
  let variant = 'primary';
  let fontColor = 'white';

  switch (buttonVariant) {
    case 'Blue':
      variant = 'primary';
      fontColor = colorValue || 'white';
      break;
    case 'Transparent':
      variant = 'secondary';
      fontColor = colorValue || 'color.impblue500';
      break;
    case 'White':
      variant = 'white';
      fontColor = colorValue || 'color.impblue500';
      break;
    case 'Green':
      variant = 'green';
      fontColor = colorValue || 'white';
      break;
    default:
      break;
  }

  return { variant, fontColor };
};
