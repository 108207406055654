/**
 * This is still a work in progress.  There are decision from stakeholders that
 * are been made as we go.  Due to that there are colors that will get added or removed.
 *
 * These does not includes all the color palette defined the the design team.
 *
 * We will polish this once the project gets into a more mature stage.
 */
export default () => ({
  primary: {
    main: '#005596',
    dark: '#4D88B6',
    bluelight: '#E0EBF2',
  },
  secondary: {
    main: '#03DAC5',
  },
  text: {
    primary: '#005596',
    secondary: '#000',
  },
  gradient: {
    bluegreenlefttoright: 'linear-gradient(102deg, rgba(0, 85, 150, .88), rgba(91, 194, 167, .88))',
    lightBlueLightGreen: 'linear-gradient(to bottom, rgba(69, 151, 211, 0.2), rgba(91, 194, 167, .2))',
    lightBlueLightGreenSoft: 'linear-gradient(to bottom, rgba(69, 151, 211, 0.125), rgba(91, 194, 167, .125))',
  },
  border: {
    light: 'rgba(0,0,0,.12)',
  },
  color: {
    implightblue100: '#daeaf6',
    impblue300: '#4d88b6',
    impblue400: '#266FA6',
    impblue500: '#005596',
    impblue700: '#00335A',
    impgray100: '#edeeee',
    impgray700: '#646466',
    impgray900: '#212121',
    impgraydark: '#393939',
    impgreen500: '#5bc2a7',
    impgreen700: '#49b496',
    impgreen50: '#ebf8f4',
    impgreen900: '#2f9f7c',
    white: '#ffffff',
    grayLight: '#f0f4f7',
    grayMedium: '#707070',
    danger: '#dc3545',
    link: '#1f846a',
  },
  social: {
    email: '#005596',
    linkedIn: '#0072b1',
    twitter: '#000000',
    facebook: '#3b5998',
    instagram: '#fb3958',
    youTube: '#c4302b',
    glassdoor: '#0CAA41',
    tiktok: '#ff0050',
  },
  shadow: {
    light: '0 3px 26px 0 rgba(98, 98, 98, 0.36)',
    darker: '0 3px 24px 0 rgba(0, 0, 0, 0.1)',
  },
});

export type ColorIndex = 'implightblue100'
| 'impblue300' | 'impblue400' | 'impblue500' | 'impblue700'
| 'impgreen50' | 'impgreen500' | 'impgreen700' | 'impgreen900'
| 'impgray100' | 'impgray700' | 'impgray900'
| 'grayLight' | 'impgraydark' | 'white';

export type ColorCategory = 'primary' | 'secondary' | 'text'
| 'gradient' | 'color';

export type SocialIconColors = 'email' | 'linkedIn' | 'twitter'
| 'facebook' | 'instagram' | 'youTube' | 'glassdoor' | 'tiktok';

export type SocialIcons = {
  email: '#005596',
  linkedIn: '#0072b1',
  twitter: '#000000',
  facebook: '#3b5998',
  instagram: '#3f729b',
  youTube: '#c4302b',
  glassdoor: '#0CAA41',
  tiktok: '#ff0050',
};

export type Social = { [ key in SocialIcons & string]: SocialIconColors };

declare module '@mui/material/styles' {
  interface Palette {
    gradient: PaletteOptions['primary'];
    color: PaletteOptions['primary'];
    bluelight: PaletteOptions['primary'];
    shadow: PaletteOptions['primary'];
    social: SocialIcons;
  }
  interface PaletteOptions {
    gradient: PaletteOptions['primary'];
    color: PaletteOptions['primary'];
    bluelight: PaletteOptions['primary'];
    shadow: PaletteOptions['primary'];
    social: SocialIcons;
  }

  interface PaletteColor {
    main: string,
    danger: string,
    darker: string;
    grayLight: string,
    grayMedium: string,
    light: string,
    white: string,
    implightblue100: string,
    impblue300: string,
    impblue400: string,
    impblue500: string,
    impblue700: string,
    impgreen50: string,
    impgreen500: string,
    impgreen700: string,
    impgreen900: string,
    impgray100: string,
    impgray700: string,
    impgray900: string,
    impgraydark: string,
    bluegreenlefttoright: string,
    lightBlueLightGreenSoft: string,
    lightBlueLightGreen: string,
    social: SocialIconColors,
    link: string,
  }

  interface PaletteColorOptions {
    main: string,
    danger: string,
    dark: string,
    darker: string;
    grayLight: string,
    grayMedium: string,
    light: string,
    white: string,
    implightblue100: string,
    impblue300: string,
    impblue400: string,
    impblue500: string,
    impblue700: string,
    impgreen50: string,
    impgreen500: string,
    impgreen700: string,
    impgreen900: string,
    impgray100: string,
    impgray700: string,
    impgray900: string,
    impgraydark: string,
    bluegreenlefttoright: string,
    lightBlueLightGreen: string,
    lightBlueLightGreenSoft: string,
    social: SocialIconColors,
    link: string,
  }
}
