import React from 'react';
import { Grid, Typography } from '@mui/material';

import formatDateTime from '#utils/formatDateTime';

import formatTime from './formatTime';
import Time from './Time';

export type TimeRangeProps = {
  start: Date,
  end: Date,
};

const TimeRange = ({
  start,
  end,
}: TimeRangeProps) => {
  const startTime = formatTime(start);
  const endTime = formatTime(end);
  const timeZone = formatDateTime(
    end!,
    {
      month: undefined,
      day: undefined,
      timeZoneName: 'short',
    },
  ).match(/([A-Z])+/g)?.[0];

  return (
    <Grid
      container
      direction="row"
      columnGap={0.2}
    >
      <Grid item xs={5} xl={3}>
        <Time time={startTime} />
      </Grid>
      -
      <Grid item xs={5} xl={3} textAlign="right">
        <Time time={endTime} />
      </Grid>
      <Grid
        item
        xs={4}
        xl={2}
        textAlign={{ xs: 'left', xl: 'right' }}
        pl={{ xs: 0, xl: 1 }}
      >
        <Typography variant="body3">
          <i>
            {timeZone}
          </i>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TimeRange;
