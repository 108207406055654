import React from 'react';
import {
  Box,
  Tabs,
  useMediaQuery,
} from '@mui/material';

import type {
  ContentfulComponentDefinition,
} from '#contentful/ContentfulComponentDefinition';

import type { ContentfulSectionSpacingDefinition } from '#hooks/useSectionSpacing';
import useSectionSpacing from '#hooks/useSectionSpacing';
import type { ContentfulBorderDefinition } from '#hooks/useBorder';
import useBorder from '#hooks/useBorder';
import { useTheme } from '@mui/system';
import SectionSettings from '#components/Section/SectionSettings';
import useBorderRoundingToPx from '#hooks/useBorderRoundingToPx';
import type { BorderRounding } from '#hooks/useBorderRoundingToPx/useBorderRoundingToPx';
import TabPanel from './TabPanel';
import type {
  TabItem,
  TabColor,
} from './TabPanel';
import TabLabel from './TabLabel';

export type TabDefinition = TabProps
& ContentfulComponentDefinition
& {
  internal: {
    type: 'ContentfulComponentTab',
  },
};

type TabProps = {
  items: TabItem[],
  tabsPosition?: 'Top' | 'Left',
  tabDrawerBackground?: TabColor,
  spacingSettings?: ContentfulSectionSpacingDefinition,
  border?: ContentfulBorderDefinition
  minHeight?: number,
  tabStyle?: 'Offset' | 'Even Height',
  borderStyle: 'Full Component' | 'Content Area Only',
  tabCornerRounding?: BorderRounding,
};

const TabDrawer = ({
  items,
  tabsPosition = 'Left',
  spacingSettings,
  tabDrawerBackground,
  border,
  minHeight,
  tabStyle = 'Offset',
  borderStyle = 'Full Component',
  tabCornerRounding,
}: TabProps) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));

  const handleChange = (event: React.SyntheticEvent, newSelectedTab: number) => {
    setSelectedTab(newSelectedTab);
  };
  const borderStyling = useBorder(border);

  const {
    paddingBottom,
    paddingTop,
    paddingLeft,
    paddingRight,
    sectionColumnsHorizontalAlignment,
    sectionColumnsVerticalAlignment,
    ...tabSettings
  } = spacingSettings || {};

  const tabContentSettings = {
    paddingBottom,
    paddingTop,
    paddingLeft,
    paddingRight,
    sectionColumnsHorizontalAlignment,
    sectionColumnsVerticalAlignment,
  };

  //  Calculate section settings
  const responsiveSectionSpacing = useSectionSpacing(
    {
      ...tabSettings,
    },
    {
      mtmd: 0,
      mbmd: 0,
      mtlg: 0,
      mblg: 0,
      mllg: 0,
      mrlg: 0,
      ptmd: 0,
      pbmd: 0,
      plmd: 0,
      prmd: 0,
      ptlg: 0,
      pblg: 0,
      pllg: 0,
      prlg: 0,
      plxl: 0,
      prxl: 0,
      columnSpacinglg: 4,
    },
  );

  const getTabsOrientation = () => {
    if (isMobile) {
      return 'horizontal';
    }
    return tabsPosition === 'Top' ? 'horizontal' : 'vertical';
  };

  const borderRadius = useBorderRoundingToPx(tabCornerRounding);

  return (
    <SectionSettings {...responsiveSectionSpacing}>
      <Box
        sx={{
          flexGrow: 1,
          ...(getTabsOrientation() === 'vertical' && { display: 'flex' }),
          ...(borderStyle === 'Full Component' && { ...borderStyling }),
          overflow: 'hidden',
          borderRadius,
        }}
      >
        <Tabs
          orientation={getTabsOrientation()}
          variant="fullWidth"
          value={selectedTab}
          onChange={handleChange}
          {...(isMobile && { variant: 'scrollable', scrollButtons: true, allowScrollButtonsMobile: true })}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          sx={{
            backgroundColor: tabDrawerBackground ? tabDrawerBackground.value : 'transparent',
            minWidth: { xs: 'auto', lg: 'max-content' },
            '& .MuiTabs-scrollButtons.Mui-disabled': {
              opacity: 0.3,
            },
          }}
        >
          {
          items?.map((item: TabItem, index) => (
            <TabLabel
              key={item.id}
              index={index}
              active={selectedTab === index}
              onChange={handleChange}
              {...item}
            />
          ))
        }
        </Tabs>

        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          {items?.map((item: TabItem, index) => (
            <div key={item.id} hidden={selectedTab !== index}>
              <TabPanel
                {...item}
                minHeight={minHeight}
                spacingSettings={tabContentSettings}
                tabStyle={tabStyle}
                borderStyling={borderStyle === 'Content Area Only' ? border : undefined}
              />
            </div>
          ))}
        </Box>
      </Box>
    </SectionSettings>
  );
};

export default TabDrawer;
