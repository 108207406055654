import React from 'react';
import { Grid } from '@mui/material';

import type { ContentfulSectionSpacingDefinition } from '#hooks/useSectionSpacing';
import useSectionSpacing from '#hooks/useSectionSpacing';
import SectionSettings from '#components/Section/SectionSettings';
import type {
  ContentfulComponentDefinition,
} from '../ContentfulComponentDefinition';

import type { AccordionItemDefinition } from './AccordionItem';
import AccordionItem from './AccordionItem';

export type AccordionListDefinition = ContentfulComponentDefinition & {
  items: AccordionItemDefinition[],
  internal: {
    type: 'ContentfulComponentAccordion'
  }
  spacingSettings?: ContentfulSectionSpacingDefinition,
};

export type AccordionListProps = {
  content: AccordionListDefinition,
};

const AccordionList = ({
  content: {
    items,
    spacingSettings,
  },
}: AccordionListProps) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (
    _: React.SyntheticEvent,
    newExpanded: boolean,
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  //  Calculate section settings
  const responsiveSectionSpacing = useSectionSpacing(
    {
      ...spacingSettings,
    },
    {
      mtmd: 0,
      mbmd: 0,
      mtlg: 0,
      mblg: 0,
      mllg: 0,
      mrlg: 0,
      ptmd: 0,
      pbmd: 0,
      plmd: 0,
      prmd: 0,
      ptlg: 0,
      pblg: 0,
      pllg: 0,
      prlg: 0,
      plxl: 0,
      prxl: 0,
      columnSpacinglg: 4,
    },
  );

  return (
    <SectionSettings {...responsiveSectionSpacing}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        rowSpacing={4}
        px={2}
      >
        {items?.map(
          (item: AccordionItemDefinition) => (
            <Grid key={item.id} item xs={12}>
              <AccordionItem
                {...item}
                expanded={expanded}
                handleChange={handleChange}
              />
            </Grid>
          ),
        )}
      </Grid>
    </SectionSettings>
  );
};

export default AccordionList;
