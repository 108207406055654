import paletteTheme from '#components/Layout/theme/palette.theme';
import { Typography } from '@mui/material';
import React from 'react';

export type TableHeaderCellProps = {
  title: string,
};

const TableHeaderCell = ({
  title,
}: TableHeaderCellProps) => (
  <Typography
    variant="caption"
    sx={{ color: paletteTheme().color.impgray900 }}
  >
    <b>{title}</b>
  </Typography>
);

export default TableHeaderCell;
