import type { JobTableDefinition } from '../models/JobTableDefinition';

export default ({
  guid,
  title,
  category,
  description,
  pubDate,
  jobType,
  jobLocation,
  Country,
  emailAddress,
  applicationUrl,
  link,
}: any): JobTableDefinition => ({
  id: guid._text,
  guid: guid._text,
  title: title._text,
  category: category._text,
  description: description._text,
  pubDate: pubDate._text,
  jobType: jobType._text,
  location: jobLocation._text,
  country: Country._text,
  emailAddress: emailAddress._text,
  applicationUrl: applicationUrl._text,
  link: link._text,
});
