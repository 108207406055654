import React, { useMemo } from 'react';

import type { ContentfulComponentDefinition } from '#components/ContentfulComponent';
import { Box } from '@mui/material';

export type VideoDefinition = ContentfulComponentDefinition & {
  youTubeUrl: string,
  internal: {
    type: 'ContentfulComponentVideo'
  }
};

export type VideoProps = {
  content: VideoDefinition
};

const Video = ({
  content: {
    youTubeUrl,
  },
}: VideoProps) => {
  const youtubeEmbeddedUrl = useMemo(
    () => youTubeUrl
    && `${youTubeUrl.replace('watch?v=', 'embed/')}?showinfo=0&modestbranding=1`,
    [youTubeUrl],
  );

  return (
    <Box display="flex" justifyContent="center" my={2}>
      <iframe
        src={youtubeEmbeddedUrl}
        title="promotional video"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          border: 0,
          width: '100%',
          aspectRatio: '16 / 9',
        }}
      />
    </Box>
  );
};

export default Video;
