import React from 'react';
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import Container from '#components/Container';
import NavBarMobile from '#components/NavBarMobile';
import NavBarDesktop from '#components/NavBarDesktop';
import useQuery from './_useQuery';
import useMenu from './_useMenu';

const Header = () => {
  const {
    menuNav,
  } = useQuery();
  const {
    palette,
    breakpoints,
  } = useTheme();
  const menuItems = useMenu(menuNav.items);
  const menu = menuItems.map((item, index) => ({
    ...item,
    expandedItem: menuNav.items[index].expandedItem,
  }));
  const isDesktop = useMediaQuery(breakpoints.up('lg'));

  return (
    <AppBar
      variant="outlined"
      elevation={0}
      sx={{
        backgroundColor: 'white',
        border: 'none',
        boxShadow: palette.shadow?.light,
      }}
    >
      <Container maxWidth="xl" disableGutters>
        <Toolbar
          sx={{
            px: { sx: 2, md: 3 },
          }}
        >
          {isDesktop ? (
            <NavBarDesktop
              menu={menu}
            />
          ) : (
            <NavBarMobile
              menu={menu}
            />
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
