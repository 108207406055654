import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import type { ContentfulComponentDefinition } from '#contentful/ContentfulComponentDefinition';
import FullWidth from '#components/Section/Sections/FullWidth';
import type { TimeItemLabel, TimeLineItemProps } from './TimeLine/TimeLine';
import TimeLine from './TimeLine/TimeLine';
import type { TableContentItemDefinition } from './TableContentItem/TableContentItem';

export type TableContentDefinition = ContentfulComponentDefinition & {
  title: string,
  tableContentItemTitleColor: TableColor,
  tableContentItemOverlineColor: TableColor,
  tableContentItemDotFillColor: TableColor,
  tableContentItemDotHoverColor: TableColor,
  tableContentItemTimelineColor: TableColor,
  items: TableContentItemDefinition[],
  internal: {
    type: 'ContentfulComponentTableContent',
  },
};

type TableContentProps = {
  content: TableContentDefinition;
};

export type TableColor = {
  value: string,
};

const TableContent = ({ content }: TableContentProps) => {
  const {
    items,
    tableContentItemDotFillColor,
    tableContentItemDotHoverColor,
    tableContentItemOverlineColor,
    tableContentItemTimelineColor,
    tableContentItemTitleColor,
  } = content;

  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const handleTimelineItemClick = (index: number) => {
    sectionRefs.current[index]?.scrollIntoView({ behavior: 'smooth' });
    setSelectedItem(index);
  };

  const [timeLineContents, setTimeLineContents] = useState<TimeLineItemProps>({
    labels: [],
    dotFillColor: 'red',
    dotHoverColor: 'red',
    lineColor: 'red',
    overlineColor: 'red',
    titleColor: 'red',
    onItemClick: handleTimelineItemClick,
    selectedItem,
  });
  const [isTopFixed, setIsTopFixed] = useState(false);
  const [isBottomFixed, setIsBottomFixed] = useState(false);

  const handleScroll = () => {
    const contentElement = document.getElementById('content');
    const lastSection = sectionRefs.current[sectionRefs.current.length - 1];

    if (contentElement && lastSection) {
      const scrollPosition = window.scrollY;
      const triggerPoint = contentElement.offsetTop - 20; // 20px offset for sticky effect
      const lastSectionBottom = lastSection.offsetTop + lastSection.offsetHeight;
      const viewportHeight = window.innerHeight;

      if (scrollPosition + viewportHeight >= lastSectionBottom) {
        setIsTopFixed(false);
        setIsBottomFixed(true);
      } else if (scrollPosition > triggerPoint) {
        setIsTopFixed(true);
        setIsBottomFixed(false);
      } else {
        setIsTopFixed(false);
        setIsBottomFixed(false);
      }
    }

    const sections = sectionRefs.current;
    const scrollPositionMiddle = window.scrollY + window.innerHeight / 2;

    sections.forEach((section, index) => {
      if (section) {
        const { offsetTop, offsetHeight } = section;
        if (scrollPositionMiddle > offsetTop && scrollPositionMiddle < offsetTop + offsetHeight) {
          setSelectedItem(index);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const labels: TimeItemLabel[] = [];
    items.forEach((element) => {
      labels.push({ textOverline: element.textOverline, titleContent: element.titleContent });
    });
    setTimeLineContents({
      labels,
      dotFillColor: tableContentItemDotFillColor.value,
      dotHoverColor: tableContentItemDotHoverColor.value,
      overlineColor: tableContentItemOverlineColor.value,
      lineColor: tableContentItemTimelineColor.value,
      titleColor: tableContentItemTitleColor.value,
      onItemClick: handleTimelineItemClick,
      selectedItem,
    });
  }, [items,
    tableContentItemDotFillColor,
    tableContentItemDotHoverColor,
    tableContentItemOverlineColor,
    tableContentItemTimelineColor,
    tableContentItemTitleColor,
    selectedItem]);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={2}
        sx={{
          flexWrap: 'wrap',
          display: 'flex',
          alignContent: 'flex-end',
        }}
      >
        <Box
          id="timeline"
          sx={{
            position: isTopFixed ? 'fixed' : 'static',
            top: isTopFixed && !isBottomFixed ? '80px' : 'initial',
            bottom: isBottomFixed ? 0 : 'end',
            height: isTopFixed || isBottomFixed ? 'auto' : '100%',
            width: '200px',
            overflowY: 'auto',
            backgroundColor: 'white',
            zIndex: 1,
          }}
        >
          <TimeLine
            {...timeLineContents}
            selectedItem={selectedItem}
            onItemClick={handleTimelineItemClick}
          />
        </Box>
      </Grid>
      <Grid item xs={10} id="content">
        <Box>
          {items.map((component, index) => (
            <Box
              key={component.id}
              ref={(el: HTMLDivElement) => {
                sectionRefs.current[index] = el;
                return sectionRefs;
              }}
              sx={{ padding: '16px' }}
            >
              <FullWidth content={component.component} />
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default TableContent;
