import type { ChangeEvent } from 'react';
import React, { useMemo, useState, useContext } from 'react';

import {
  FormControlLabel,
  Grid,
  Box,
  Skeleton,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import type { GridFilterItem, GridFilterModel } from '@mui/x-data-grid';
import {
  filter,
  first,
  flow,
  isEmpty,
  map,
} from 'lodash/fp';

import SearchBar from '#components/SearchBar';
import SmartLink from '#components/SmartLink';
import useColor from '#hooks/useColor';
import RegionSelector from '#components/RegionSelector';
import type { ArloRegionCodes } from '#services/arlo';
import { GeolocationContext } from '#providers/GeolocationProvider';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import type { LocationProps } from './_useNormalizeLocation';
import normalizeLocation from './_useNormalizeLocation';
import useSearchedItems from './_useSearchedItems';
import type { DataTableProps } from './DataTable.types';

const translationkey = 'Components.DataTable';

const DataTable = <T,>({
  columns,
  items,
  noItemsCopy,
  heading,
  headingVariant = 'h2',
  headingFontColor = 'impblue300',
  text,
  href,
  slug,
  anchorTag,
  searchable = false,
  initialRowCount = 10,
  rowsPerPageOptions = [3, 5, 10, 25, 50],
  hasRegionSelector = false,
  toggleIncludeOnline = false,
  includeOnline = true,
  setIncludeOnline,
  viewAllLinkLabel,
  loadingItems,
}: DataTableProps<T>) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const { region, setRegion } = useContext(GeolocationContext);

  //  get filter state based on query params
  //  must check to ensure the window exists
  const queryParams = useMemo(
    () => new URLSearchParams(
      typeof window === 'undefined'
        ? undefined
        : window.location.search,
    ),
    [],
  );

  const [filterItem, setFilterItem] = useState<GridFilterItem | undefined>(
    queryParams.get('field')
      ? {
        columnField: queryParams.get('field')!,
        operatorValue: queryParams.get('operator')!,
        value: queryParams.get('value')!,
      }
      : undefined,
  );

  const searchableColumns = useMemo(
    () => flow(
      filter('searchable'),
      map('valueField'),
    )(columns),
    [columns],
  );

  const [searchValue, setSearchValue] = useState(
    queryParams.get('search') ?? '',
  );

  // location is sometimes a string, sometimes an object
  const itemsToSearch = map(normalizeLocation)(items as LocationProps[]);

  const searchedItems = useSearchedItems(
    itemsToSearch,
    searchableColumns,
    searchValue,
  );

  useMemo(
    () => {
      if (typeof window !== 'undefined') {
        //  update values based on what's being passed in
        const hasFilter = !isEmpty(filterItem?.value);
        const hasSearch = !isEmpty(searchValue);
        const notOnline = !includeOnline ?? false;
        const nonUsRegion = isEmpty(region)
          ? !!queryParams.get('region')
          : region !== 'us';

        //  update the filters
        if (hasFilter) {
          queryParams.set('field', filterItem?.columnField!);
          queryParams.set('operator', filterItem?.operatorValue!);
          queryParams.set('value', filterItem?.value);
        } else {
          queryParams.delete('field');
          queryParams.delete('operator');
          queryParams.delete('value');
        }

        //  update the search param
        if (hasSearch) {
          queryParams.set('search', searchValue!);
        } else {
          queryParams.delete('search');
        }

        //  update the include online param
        if (notOnline) {
          queryParams.set('online', 'false');
        } else {
          queryParams.delete('online');
        }

        //  update the region info
        if (nonUsRegion) {
          const newRegion = region
            ?? queryParams.get('region')! as ArloRegionCodes;
          queryParams.set('region', newRegion);
          setRegion(newRegion);
        } else {
          queryParams.delete('region');
        }

        //  determine the shape of the url
        let url = '';
        if (hasFilter || hasSearch || notOnline || nonUsRegion) {
          url = `?${queryParams.toString()}`;
        } else {
          url = window.location.href
            .split('?')[0].toString();
        }

        //  update the url
        window.history.replaceState(
          {},
          document.title,
          url,
        );
      }
    },
    [
      queryParams,
      filterItem,
      searchValue,
      includeOnline,
      region,
      setRegion,
    ],
  );

  const [pageSize, setPageSize] = useState(initialRowCount);

  const headingTextColor = useColor('color', headingFontColor);
  const headerBgColor = useColor('color', 'impgreen500');
  const headerFontColor = useColor('color', 'impgrey900');

  const handleFilterModelChange = (
    { items: filterItems }: GridFilterModel,
  ) => {
    //  for the basic version of DataGrid, only one filter item is allowed at a time
    setFilterItem(first(filterItems));
  };

  const handleInludeOnlineChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setIncludeOnline!(event.target.checked);
  };

  const listedItems = useMemo(
    () => (isEmpty(searchValue) ? items : items.filter(
      (item) => searchedItems.find((searchedItem) => searchedItem.id === item.id),
    )),
    [searchValue, items, searchedItems],
  );

  if (loadingItems) {
    return (
      <Skeleton
        variant="rounded"
        animation="wave"
        height={400}
        sx={{ bgcolor: 'palette.gradient?.bluegreenlefttoright' }}
      />
    );
  }

  return (
    <Grid
      container
      direction="column"
      px={2}
    >
      {heading && (
        <Grid item xs={12}>
          <Typography
            variant={headingVariant ?? 'h2'}
            sx={{
              textAlign: 'center',
              color: headingTextColor,
            }}
          >
            {heading}
          </Typography>
        </Grid>
      )}

      {text && (
        <Grid item xs={8}>
          <Typography
            variant="subheading2"
            sx={{
              mb: 6,
              maxWidth: '46rem',
              display: 'block',
              textAlign: 'center',
            }}
          >
            {text}
          </Typography>
        </Grid>
      )}

      {(hasRegionSelector || toggleIncludeOnline) && (
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={2}
          mb={4}
        >
          {toggleIncludeOnline && setIncludeOnline && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box display="flex" justifyContent="center">
                <FormControlLabel
                  label={t(`${translationkey}.IncludeOnline`) as string}
                  control={(
                    <Switch
                      checked={includeOnline}
                      onChange={handleInludeOnlineChange}
                    />
                  )}
                />
              </Box>
            </Grid>
          )}

          {hasRegionSelector && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box display="flex" justifyContent="center" mx="auto">
                <RegionSelector
                  defaultValue={region}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      )}

      {(href || slug || anchorTag) && (
        <Grid
          item
          alignSelf="flex-end"
          sx={{
            mb: 2.5,
            pr: 1.25,
          }}
        >
          <Typography
            variant="subtitle1"
            className="ContentfulLink"
          >
            <SmartLink
              href={href}
              slug={slug}
              anchorTag={anchorTag}
              sx={{
                mb: 2.5,
                pr: 1.25,
              }}
            >
              { viewAllLinkLabel }
            </SmartLink>
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        {(items.length > 0)
          ? (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="stretch"
            >
              {searchable && (
                <Grid
                  item
                  container
                  justifyContent="center"
                  py={3}
                  px={2}
                  borderRadius={1}
                  bgcolor={headerBgColor}
                >
                  <Grid item>
                    <SearchBar
                      value={searchValue ?? ''}
                      onChange={setSearchValue}
                      inputAriaLabel="search items"
                      sx={{
                        width: { xs: '100%;', md: 600 },
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid item>
                <DataGrid
                  rows={listedItems}
                  columns={columns}
                  sortingOrder={['desc', 'asc']}
                  pagination
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                  rowsPerPageOptions={rowsPerPageOptions}
                  autoHeight
                  getRowHeight={() => 'auto'}
                  onFilterModelChange={handleFilterModelChange}
                  localeText={{
                    MuiTablePagination: {
                      labelRowsPerPage: t(`${translationkey}.RowsPerPage`),
                      labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t(`${translationkey}.Of`)} ${count}`,
                    },
                  }}
                  initialState={{
                    filter: {
                      filterModel: filterItem
                        ? {
                          items: [filterItem],
                        }
                        : undefined,
                    },
                  }}
                  sx={{
                    overflowX: { xs: 'scroll', md: 'unset' },
                    border: 0,
                    '& .MuiDataGrid-columnHeaders': {
                      color: headerFontColor,
                      background: `${headerBgColor}5c`,
                    },
                    '& .MuiDataGrid-menuIcon': {
                      marginLeft: '2px',
                      visibility: 'visible !important',
                      width: 'auto !important',
                      opacity: 0.8,
                    },
                    '& .MuiDataGrid-sortIcon': {
                      opacity: 0.8,
                      visibility: 'visible',
                    },
                    '& .MuiDataGrid-renderingZone': {
                      minHeight: '60px !important',
                    },
                    '& .MuiDataGrid-cell': {
                      minHeight: '60px !important',
                    },
                    '& .MuiDataGrid-row': {
                      minHeight: '60px !important',
                    },
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <Box
              sx={{
                background: palette.gradient?.lightBlueLightGreenSoft,
              }}
              mx={{ xs: 0, md: '6%', lg: '16%' }}
              p={{ xs: 2, sm: 4, md: 8 }}
            >
              <Typography textAlign="center">
                {noItemsCopy}
              </Typography>
            </Box>
          )}
      </Grid>
    </Grid>
  );
};

export default DataTable;
