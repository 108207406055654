import {
  flow,
  get,
  map,
} from 'lodash/fp';

import dynamicsClient from '../client';
import type { JobTableDefinition } from '../models/JobTableDefinition';
import xmlToJson from '../_xmlToJson';
import convertApiToDomain from './_convertApiToDomain';

const apiEndpoint = '/JobListing/Feed/d75dbca3-034f-4bfe-acd4-40d125c2a4fd';

export default (): Promise<JobTableDefinition[]> => (
  dynamicsClient.get(apiEndpoint)
    .then(flow(
      get('data'),
      xmlToJson,
      get('rss.channel.item'),
      map(convertApiToDomain),
    ))
);
